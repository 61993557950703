<template>
  <div class="main">
    <van-nav-bar class="top-bar has-bg-bar" title="活动详情" left-arrow @click-left="onClickLeft"/>
    <div class="detail-container">
      <div class="detail-title">{{ detail.title }}</div>
      <div class="detail-time">{{ detail.create_time }}</div>
      <div class="detail-content" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import {NavBar} from "vant";
import {reactive} from "vue";
import {activityDetail} from "@/api/game";
import {date} from "@/utils/function";
import {useRoute} from "vue-router";
import "@/style/common.css"

export default {
  name: "ActivityDetail",
  components: {
    [NavBar.name]: NavBar,
  },
  setup() {
    const detail = reactive({
      title: '',
      content: '',
      create_time: 0,
    })
    const {params} = useRoute();
    const onClickLeft = () => history.back();
    activityDetail(params.activity_id).then(({data}) => {
      detail.title = data.title
      detail.create_time = date(data.create_time, 'Y-m-d H:i:s')
      detail.content = data.content
    })
    return {
      detail,
      onClickLeft,
    }
  }
}
</script>

<style scoped>
.top-bar :deep(.van-nav-bar__content) {
  background: url("../../assets/images/personal/msg-bar.png") bottom center no-repeat;
  background-size: 100% auto;
}
.top-bar::after{
  display: none;
}
.detail-container {
  padding: 12px 16px;
  text-align: left;
}

.detail-title {
  font-size: 22px;
  font-weight: 500;
  color: #1D2129;
  line-height: 30px;
}

.detail-time {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #86909C;
  line-height: 12px;
}

.detail-content {
  width:100%;
  overflow:hidden;
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  line-height: 22px;
  padding: 12px 0px;
}
</style>
